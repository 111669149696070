<template>
  <div class="container">
    <TheHeader />
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card">
          <div class="card-body text-center">
            <BaseSpinner v-if="verified === true" :loading="true">
              <p><img width="128" height="128" src="@/assets/images/lock.gif"></p>
              <p>We are activating your account and will redirect you shortly.</p>
            </BaseSpinner>
            <div v-else class="text-center">
              <p>The link you clicked appears to be invalid or your account is already active.</p>
              <a-button type="primary" @click="$router.push({ name: 'index' })">
                Go to Dashboard
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@/components/Base/BaseSpinner'
import TheHeader from '@/components/TheHeader'

export default {
  components: {
    BaseSpinner,
    TheHeader,
  },

  data() {
    return {
      verified: true,
    }
  },

  async created() {
    /**
     * The API will return an authorization token and the user object to help us
     * log in the user automatically.
     */
    try {
      var { data } = await this.$http.put(`/me/verified`, {
        emailToken: this.$route.params.id,
      })

      this.$store.commit('user', data)
    } catch (err) {
      if (err.response) {
        this.$message.error(err.response.data.message)
      } else {
        this.$message.error(err.message)
      }

      this.verified = false
    } finally {
      setTimeout(() => {
        // this.$router.replace({ name: 'index' })
        window.location.href = '/'
      }, 1500)
    }
  },
}
</script>
